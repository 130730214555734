/* eslint-disable max-len */
/* eslint-disable camelcase */
import { Service } from '@/common/server/index'
import { Message } from 'view-design'

const baseURL = `${process.env.VUE_APP_MI_OAP}/tracing/v1/m2h`
const baseURLDev = 'http://dev-mioap.api.xiaomi.net/tracing/v1/m2h'

export function getTransferUrl(url, query, isDev) {
  return Service({
    url,
    method: 'POST',
    params: query,
    baseURL: isDev ? baseURLDev : baseURL,
  })
}

/**
参数示例
transfer?type=%2Fmetrics%2Fapp&treeId=17745&treePath=xiaomi.cloud.cdc.mi-telemetry.examples&appName=robot-web&deploySpace=robot-web-staging&cluster=kscn-tj-serving-k8s
/metrics/app
{
  treeId: 17745,
  appName: 'robot-web',
  deploySpace: 'robot-web-staging',
  cluster: 'staging',
}

transfer?type=%2Ftrace%2Fagentconfig&treeId=17745&appName=robot-web
/trace/config
{
  "treeId": 17745,
  "appName": "robot-web"
}
 */

export function handleTransfer(pathRaw, query, router) {
  if (pathRaw === '/transfer') {
    const { type, __dev, ...other } = query
    console.log('getTransferUrl request', type, other)
    getTransferUrl(type, other, __dev).then(res => {
      // window.location.href = res.data
      console.log('getTransferUrl res', res)
      const { code, url: path, msg, grafana } = res.data // .replace('http://hera.be.test.mi.com', '')
      if (code === 0) {
        if (grafana && grafana.url) {
          // eslint-disable-next-line no-underscore-dangle
          window.__grafanaCache = {
            [path]: grafana.url,
          }
          window.history.pushState(grafana, '', path)
        } else {
          router.push({
            path,
          })
        }
      } else {
        Message.error({
          content: `${msg || '链接跳转异常'}`,
          duration: 4,
        })
        router.push({
          path: path || '/project-target-monitor/application',
        })
      }
    })
  }
}
