export default {
  mainMenu: {
    application: 'Applications',
    metric: 'Metrics',
    log: 'Logging',
    trace: 'Tracing',
    setting: 'Settings',
    help: 'Help',
    manual: 'manual',
  },
};
