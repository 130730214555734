export default {
  mainMenu: {
    application: '应用中心',
    metric: '指标监控',
    log: '日志服务',
    trace: '链路追踪',
    setting: '基础设置',
    help: '帮助文档',
    manual: '用户手册',
  },
};
