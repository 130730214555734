<template>
  <div id="main-root">
    <div>
      <MainMenu />
    </div>
    <div id="root-view" class="app-view-box" v-html="content"></div>
  </div>
</template>
<script>
import MainMenu from '@/components/main-menu.vue'

export default {
  name: 'App',
  components: { MainMenu },
  props: {
    loading: Boolean,
    content: String,
  },
  data() {
    return {
      isPro: process.env.NODE_ENV === 'production',
    }
  },
}
</script>

<style lang="less">
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.top-menu {
  height: 60px;
}
</style>
