const selectedLang = window.localStorage.getItem('selectedLang')
export default {
  namespaced: true,
  state: {
    theme: '',
    language: selectedLang === null ? 'en-US' : selectedLang,
  },
  mutations: {
    setTheme(state, theme) {
      state.theme = theme;
    },
    setLanguage(state, lang) {
      state.language = lang;
    },
  },
  actions: {
    setLanguage({ commit }, lang) {
      commit('setLanguage', lang);
      localStorage.setItem('selectedLang', lang);
    },
  },
};
